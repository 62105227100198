<template>
    <div>
        <transition
            enter-active-class="transition-all ease-in-out duration-300 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-all ease-in-out duration-300 transform"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <Loader
                v-if="isLoading"
                class="flex h-screen py-40 justify-center items-center"
                type="lg"
            />
            <div
                v-else
                class="p-8 mt-4 lg:mt-0 lg:px-20 desktop:px-36 bg-gray-100"
            >
                <h2 class="flex font-medium text-2xl mb-5">
                    {{ $t("booking.past_booking") }}
                </h2>
                <div class="flex justify-center">
                    <div
                        class="
                            bg-white
                            mt-8
                            py-8
                            px-4
                            sm:p-8
                            w-full
                            max-w-7xl
                            rounded-md
                            shadow-lg
                        "
                    >
                        <div class="hidden lg:block">
                            <div
                                class="
                                    grid grid-cols-2
                                    w-full
                                    grid-flow-row
                                    gap-x-20
                                    items-center
                                "
                            >
                                <div class="mb-5">
                                    <button
                                        :disabled="displayType === 0"
                                        @click="displayType = 0"
                                        class="
                                            focus:outline-none
                                            mr-3
                                            p-1
                                            bg-yellow-400
                                            rounded-md
                                            shadow-md
                                        "
                                        :class="{
                                            'filter grayscale':
                                                displayType === 1
                                        }"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-list-numbers
                                            "
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#ffffff"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M11 6h9" />
                                            <path d="M11 12h9" />
                                            <path d="M12 18h8" />
                                            <path
                                                d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4"
                                            />
                                            <path d="M6 10v-6l-2 2" />
                                        </svg>
                                    </button>
                                    <button
                                        :disabled="displayType === 1"
                                        @click="drawChart(graphType)"
                                        class="
                                            focus:outline-none
                                            ml-3
                                            p-1
                                            bg-yellow-400
                                            rounded-md
                                            shadow-md
                                        "
                                        :class="{
                                            'filter grayscale':
                                                displayType === 0
                                        }"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-chart-bar
                                            "
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#ffffff"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="3"
                                                y="12"
                                                width="6"
                                                height="8"
                                                rx="1"
                                            />
                                            <rect
                                                x="9"
                                                y="8"
                                                width="6"
                                                height="12"
                                                rx="1"
                                            />
                                            <rect
                                                x="15"
                                                y="4"
                                                width="6"
                                                height="16"
                                                rx="1"
                                            />
                                            <line
                                                x1="4"
                                                y1="20"
                                                x2="18"
                                                y2="20"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div></div>
                                <div
                                    v-if="displayType === 0"
                                    class="font-medium text-xl mb-5"
                                >
                                    <h2 class="border-b border-gray-200 mb-4">
                                        Distribution Type
                                    </h2>
                                    <select
                                        v-model="distributionType"
                                        @change="changeDistribution"
                                        class="select-dropdown w-full"
                                    >
                                        <option selected value="all">
                                            {{ $t("booking.all") }}
                                        </option>
                                        <option value="flow">Flow</option>
                                        <option value="external">
                                            External
                                        </option>
                                    </select>
                                </div>
                                <div
                                    v-if="displayType === 0"
                                    class="font-medium text-xl mb-5"
                                >
                                    <h2 class="border-b border-gray-200 mb-4">
                                        {{ $t("booking.orderby") }}
                                    </h2>
                                    <select
                                        v-model="order"
                                        @change="getBookings"
                                        class="select-dropdown w-full"
                                    >
                                        <option
                                            :value="{
                                                type: 'occupy_in',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.occupy_in_new") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'occupy_in',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.occupy_in_old") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'payment_time',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.payment_time_new") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'payment_time',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.payment_time_old") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'book_price',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.price_high") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'book_price',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.price_low") }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="displayType === 0" class="mb-5">
                                <h2
                                    class="
                                        font-medium
                                        text-xl
                                        border-b border-gray-200
                                        mb-4
                                    "
                                >
                                    {{ $t("booking.time_period") }}
                                </h2>
                                <div class="flex justify-between">
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="0"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.alltime") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="1"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.today") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="2"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.past7") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="3"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.past30") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="4"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.current_month") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="5"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.custom") }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="timePeriod === 5 && displayType === 0"
                                class="
                                    grid grid-cols-2
                                    w-full
                                    grid-flow-row
                                    gap-x-20
                                "
                            >
                                <div class="font-medium text-xl mb-5">
                                    <span class="text-sm text-gray-500">{{
                                        $t("dashboard.start_date")
                                    }}</span>
                                    <!--                                    <input-->
                                    <!--                                        type="date"-->
                                    <!--                                        v-model="startDate"-->
                                    <!--                                        @change="updateFilter"-->
                                    <!--                                        class="-->
                                    <!--                                            border-b border-gray-400-->
                                    <!--                                            w-full-->
                                    <!--                                            focus:outline-none-->
                                    <!--                                        "-->
                                    <!--                                    />-->
                                    <div
                                        class="
                                            font-normal
                                            text-base
                                            flex
                                            items-center
                                        "
                                    >
                                        {{ startDate.replaceAll("/", "-") }}
                                        <button
                                            class="ml-3"
                                            @click="startCalendar = true"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar
                                                "
                                                width="28"
                                                height="28"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <rect
                                                    x="4"
                                                    y="5"
                                                    width="16"
                                                    height="16"
                                                    rx="2"
                                                />
                                                <line
                                                    x1="16"
                                                    y1="3"
                                                    x2="16"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="8"
                                                    y1="3"
                                                    x2="8"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="11"
                                                    x2="20"
                                                    y2="11"
                                                />
                                                <line
                                                    x1="11"
                                                    y1="15"
                                                    x2="12"
                                                    y2="15"
                                                />
                                                <line
                                                    x1="12"
                                                    y1="15"
                                                    x2="12"
                                                    y2="18"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <CalendarModal
                                        v-if="startCalendar"
                                        :sel-date="startDate"
                                        @onSelect="selectStartDate"
                                        @onCancel="startCalendar = false"
                                    />
                                </div>
                                <div class="font-medium text-xl mb-5">
                                    <span class="text-sm text-gray-500">{{
                                        $t("dashboard.end_date")
                                    }}</span>
                                    <div
                                        class="
                                            font-normal
                                            text-base
                                            flex
                                            items-center
                                        "
                                    >
                                        {{ endDate.replaceAll("/", "-") }}
                                        <button
                                            class="ml-3"
                                            @click="endCalendar = true"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar
                                                "
                                                width="28"
                                                height="28"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <rect
                                                    x="4"
                                                    y="5"
                                                    width="16"
                                                    height="16"
                                                    rx="2"
                                                />
                                                <line
                                                    x1="16"
                                                    y1="3"
                                                    x2="16"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="8"
                                                    y1="3"
                                                    x2="8"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="4"
                                                    y1="11"
                                                    x2="20"
                                                    y2="11"
                                                />
                                                <line
                                                    x1="11"
                                                    y1="15"
                                                    x2="12"
                                                    y2="15"
                                                />
                                                <line
                                                    x1="12"
                                                    y1="15"
                                                    x2="12"
                                                    y2="18"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <CalendarModal
                                        v-if="endCalendar"
                                        :sel-date="endDate"
                                        :min-date="startDate"
                                        @onSelect="selectEndDate"
                                        @onCancel="endCalendar = false"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="block lg:hidden">
                            <div
                                class="
                                    grid grid-cols-3
                                    w-full
                                    grid-flow-row
                                    gap-x-8
                                    items-center
                                "
                            >
                                <div class="mb-5">
                                    <button
                                        :disabled="displayType === 0"
                                        @click="displayType = 0"
                                        class="
                                            focus:outline-none
                                            mr-3
                                            mb-2
                                            p-1
                                            bg-yellow-400
                                            rounded-md
                                            shadow-md
                                        "
                                        :class="{
                                            'filter grayscale':
                                                displayType === 1
                                        }"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-list-numbers
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#ffffff"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M11 6h9" />
                                            <path d="M11 12h9" />
                                            <path d="M12 18h8" />
                                            <path
                                                d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4"
                                            />
                                            <path d="M6 10v-6l-2 2" />
                                        </svg>
                                    </button>
                                    <button
                                        :disabled="displayType === 1"
                                        @click="drawChart"
                                        class="
                                            focus:outline-none
                                            p-1
                                            bg-yellow-400
                                            rounded-md
                                            shadow-md
                                        "
                                        :class="{
                                            'filter grayscale':
                                                displayType === 0
                                        }"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                icon
                                                icon-tabler
                                                icon-tabler-chart-bar
                                            "
                                            width="28"
                                            height="28"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#ffffff"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <rect
                                                x="3"
                                                y="12"
                                                width="6"
                                                height="8"
                                                rx="1"
                                            />
                                            <rect
                                                x="9"
                                                y="8"
                                                width="6"
                                                height="12"
                                                rx="1"
                                            />
                                            <rect
                                                x="15"
                                                y="4"
                                                width="6"
                                                height="16"
                                                rx="1"
                                            />
                                            <line
                                                x1="4"
                                                y1="20"
                                                x2="18"
                                                y2="20"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div
                                    v-if="displayType === 0"
                                    class="col-span-2 mb-5"
                                >
                                    <h2
                                        class="
                                            font-medium
                                            text-xl
                                            border-b border-gray-200
                                            mb-4
                                        "
                                    >
                                        {{ $t("booking.orderby") }}
                                    </h2>
                                    <select
                                        v-model="order"
                                        @change="getBookings"
                                        class="
                                            border border-gray-300
                                            bg-transparent
                                            w-full
                                            py-1
                                        "
                                    >
                                        <option
                                            :value="{
                                                type: 'occupy_in',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.occupy_in_new") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'occupy_in',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.occupy_in_old") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'payment_time',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.payment_time_new") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'payment_time',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.payment_time_old") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'book_price',
                                                ascending: true
                                            }"
                                        >
                                            {{ $t("booking.price_high") }}
                                        </option>
                                        <option
                                            :value="{
                                                type: 'book_price',
                                                ascending: false
                                            }"
                                        >
                                            {{ $t("booking.price_low") }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div
                                v-if="displayType === 0"
                                class="font-medium text-xl mb-5"
                            >
                                <h2 class="border-b border-gray-200 mb-4">
                                    Distribution Type
                                </h2>
                                <select
                                    v-model="distributionType"
                                    @change="getBookings"
                                    class="select-dropdown w-full"
                                >
                                    <option selected value="all">
                                        {{ $t("booking.all") }}
                                    </option>
                                    <option value="flow">Flow</option>
                                    <option value="external">External</option>
                                </select>
                            </div>
                            <div v-if="displayType === 0" class="mb-5">
                                <h2
                                    class="
                                        font-medium
                                        text-xl
                                        border-b border-gray-200
                                        mb-4
                                    "
                                >
                                    {{ $t("booking.time_period") }}
                                </h2>
                                <div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="0"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.alltime") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="1"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.today") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="2"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.past7") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="3"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.past30") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="4"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.current_month") }}
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            v-model="timePeriod"
                                            :value="5"
                                            @change="updateFilter"
                                            class="mr-2"
                                            type="radio"
                                        />
                                        {{ $t("booking.custom") }}
                                    </div>
                                    <div
                                        v-if="
                                            timePeriod === 5 &&
                                            displayType === 0
                                        "
                                        class="
                                            grid grid-cols-2 grid-flow-row
                                            gap-x-4
                                            items-center
                                        "
                                    >
                                        <div>
                                            <span
                                                class="text-sm text-gray-500"
                                                >{{
                                                    $t("dashboard.start_date")
                                                }}</span
                                            >
                                            <!--                                    <input-->
                                            <!--                                        type="date"-->
                                            <!--                                        v-model="startDate"-->
                                            <!--                                        @change="updateFilter"-->
                                            <!--                                        class="-->
                                            <!--                                            border-b border-gray-400-->
                                            <!--                                            w-full-->
                                            <!--                                            focus:outline-none-->
                                            <!--                                        "-->
                                            <!--                                    />-->
                                            <div
                                                class="
                                                    font-normal
                                                    text-base
                                                    flex
                                                    items-center
                                                "
                                            >
                                                {{
                                                    startDate.replaceAll(
                                                        "/",
                                                        "-"
                                                    )
                                                }}
                                                <button
                                                    class="ml-3"
                                                    @click="
                                                        startCalendar = true
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-calendar
                                                        "
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#000000"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <rect
                                                            x="4"
                                                            y="5"
                                                            width="16"
                                                            height="16"
                                                            rx="2"
                                                        />
                                                        <line
                                                            x1="16"
                                                            y1="3"
                                                            x2="16"
                                                            y2="7"
                                                        />
                                                        <line
                                                            x1="8"
                                                            y1="3"
                                                            x2="8"
                                                            y2="7"
                                                        />
                                                        <line
                                                            x1="4"
                                                            y1="11"
                                                            x2="20"
                                                            y2="11"
                                                        />
                                                        <line
                                                            x1="11"
                                                            y1="15"
                                                            x2="12"
                                                            y2="15"
                                                        />
                                                        <line
                                                            x1="12"
                                                            y1="15"
                                                            x2="12"
                                                            y2="18"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                            <CalendarModal
                                                v-if="startCalendar"
                                                :sel-date="startDate"
                                                @onSelect="selectStartDate"
                                                @onCancel="
                                                    startCalendar = false
                                                "
                                            />
                                        </div>
                                        <div>
                                            <span
                                                class="text-sm text-gray-500"
                                                >{{
                                                    $t("dashboard.end_date")
                                                }}</span
                                            >
                                            <div
                                                class="
                                                    font-normal
                                                    text-base
                                                    flex
                                                    items-center
                                                "
                                            >
                                                {{
                                                    endDate.replaceAll("/", "-")
                                                }}
                                                <button
                                                    class="ml-3"
                                                    @click="endCalendar = true"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-calendar
                                                        "
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#000000"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <rect
                                                            x="4"
                                                            y="5"
                                                            width="16"
                                                            height="16"
                                                            rx="2"
                                                        />
                                                        <line
                                                            x1="16"
                                                            y1="3"
                                                            x2="16"
                                                            y2="7"
                                                        />
                                                        <line
                                                            x1="8"
                                                            y1="3"
                                                            x2="8"
                                                            y2="7"
                                                        />
                                                        <line
                                                            x1="4"
                                                            y1="11"
                                                            x2="20"
                                                            y2="11"
                                                        />
                                                        <line
                                                            x1="11"
                                                            y1="15"
                                                            x2="12"
                                                            y2="15"
                                                        />
                                                        <line
                                                            x1="12"
                                                            y1="15"
                                                            x2="12"
                                                            y2="18"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                            <CalendarModal
                                                v-if="endCalendar"
                                                :sel-date="endDate"
                                                :min-date="startDate"
                                                @onSelect="selectEndDate"
                                                @onCancel="endCalendar = false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="displayType === 0"
                            class="
                                hidden
                                lg:grid
                                grid-cols-2
                                w-full
                                grid-flow-row
                                gap-x-20
                            "
                        >
                            <div class="font-medium text-xl mb-5">
                                <h2 class="border-b border-gray-200 mb-4">
                                    {{ $t("booking.room_type") }}
                                </h2>
                                <select
                                    v-model="roomType"
                                    @change="getBookings"
                                    class="select-dropdown w-full"
                                >
                                    <option value="all">
                                        {{ $t("booking.all") }}
                                    </option>
                                    <option
                                        v-for="(type, index) in user
                                            .space_info_list[selectedSpace]
                                            .venue_type_list"
                                        :key="index"
                                        :value="type.venue_type"
                                    >
                                        {{ type.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="font-medium text-xl mb-5">
                                <h2 class="border-b border-gray-200 mb-4">
                                    {{ $t("booking.book_type") }}
                                </h2>
                                <select
                                    v-model="bookType"
                                    @change="getBookings"
                                    class="select-dropdown w-full"
                                >
                                    <option value="all">
                                        {{ $t("booking.all") }}
                                    </option>
                                    <option
                                        v-for="(type, index) in user
                                            .space_info_list[selectedSpace]
                                            .book_type_list"
                                        :key="index"
                                        :value="type"
                                    >
                                        {{ getBookType(type) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div
                            v-if="displayType === 0"
                            class="block lg:hidden w-full"
                        >
                            <div class="font-medium text-xl mb-5">
                                <h2 class="border-b border-gray-200 mb-4">
                                    {{ $t("booking.room_type") }}
                                </h2>
                                <select
                                    v-model="roomType"
                                    @change="getBookings"
                                    class="select-dropdown w-full"
                                >
                                    <option value="all">
                                        {{ $t("booking.all") }}
                                    </option>
                                    <option
                                        v-for="(type, index) in user
                                            .space_info_list[selectedSpace]
                                            .venue_type_list"
                                        :key="index"
                                        :value="type.venue_type"
                                    >
                                        {{ type.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="font-medium text-xl mb-5">
                                <h2 class="border-b border-gray-200 mb-4">
                                    {{ $t("booking.book_type") }}
                                </h2>
                                <select
                                    v-model="bookType"
                                    @change="getBookings"
                                    class="select-dropdown w-full"
                                >
                                    <option value="all">
                                        {{ $t("booking.all") }}
                                    </option>
                                    <option
                                        v-for="(type, index) in user
                                            .space_info_list[selectedSpace]
                                            .book_type_list"
                                        :key="index"
                                        :value="type"
                                    >
                                        {{ getBookType(type) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <Legend v-if="displayType === 0" :type="1" />
                        <div v-if="displayType === 0 && !isTableLoading">
                            <div
                                class="border-gray-300 py-4"
                                :class="{
                                    'border-b':
                                        index < bookings.length - 1 &&
                                        !booking.property_collect &&
                                        !booking.distributor,
                                    'bg-blue-100 my-2 py-2':
                                        booking.distributor,
                                    'bg-red-100 my-2 py-2':
                                        booking.property_collect
                                }"
                                v-for="(booking, index) in bookings"
                                :key="index"
                            >
                                <div
                                    class="
                                        hidden
                                        lg:grid
                                        grid-cols-5
                                        items-center
                                    "
                                >
                                    <div class="flex items-center">
                                        <div class="font-medium text-3xl mr-6">
                                            {{ index + 1 }}
                                        </div>
                                        <div>
                                            <div>
                                                {{
                                                    parseDate(booking.occupy_in)
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    parseDate(
                                                        booking.occupy_out
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-user
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <circle cx="12" cy="7" r="4" />
                                                <path
                                                    d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                                /></svg
                                            >{{
                                                booking.given_name ||
                                                booking.surname
                                                    ? `${booking.given_name} ${booking.surname}`
                                                    : "User"
                                            }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-bed
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"
                                                />
                                                <circle
                                                    cx="7"
                                                    cy="10"
                                                    r="1"
                                                /></svg
                                            >{{ booking.name }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar-time
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
                                                />
                                                <circle cx="18" cy="18" r="4" />
                                                <path d="M15 3v4" />
                                                <path d="M7 3v4" />
                                                <path d="M3 11h16" />
                                                <path
                                                    d="M18 16.496v1.504l1 1"
                                                /></svg
                                            >{{ booking.book_type_name }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-wallet
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
                                                />
                                                <path
                                                    d="M20 12v4h-4a2 2 0 0 1 0 -4h4"
                                                /></svg
                                            >{{
                                                parsePaymentTime(
                                                    booking.payment_time
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <div
                                            v-if="
                                                (booking.refund_min ||
                                                    booking.refund_min === 0) &&
                                                booking.refund_min >= 0
                                            "
                                        >
                                            <FreeCancel
                                                class="text-green-600"
                                            />
                                        </div>
                                        <div v-else>
                                            <NoCancel />
                                        </div>
                                        <div v-if="booking.promos" class="ml-1">
                                            <button
                                                @click="upgradeIndex = index"
                                                class="
                                                    p-0.5
                                                    border border-orange-400
                                                    text-orange-400
                                                    hover:border-orange-300
                                                    hover:text-orange-300
                                                    shadow-md
                                                    rounded-md
                                                    transition
                                                    duration-200
                                                    ease-linear
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="
                                                        stroke-current
                                                        icon
                                                        icon-tabler
                                                        icon-tabler-arrow-up-circle
                                                    "
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#000000"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="8"
                                                        x2="8"
                                                        y2="12"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="8"
                                                        x2="12"
                                                        y2="16"
                                                    />
                                                    <line
                                                        x1="16"
                                                        y1="12"
                                                        x2="12"
                                                        y2="8"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div
                                            class="flex justify-center"
                                            v-if="booking.state !== '1'"
                                        >
                                            <span
                                                class="
                                                    text-center
                                                    px-5
                                                    py-2
                                                    rounded-md
                                                    shadow-md
                                                    bg-gray-500
                                                    text-white
                                                "
                                                >{{
                                                    getBookingState(
                                                        booking.state
                                                    )
                                                }}</span
                                            >
                                        </div>
                                        <div
                                            v-if="
                                                booking.report_no_show &&
                                                booking.state === '1' &&
                                                booking.refundable &&
                                                mode &&
                                                mode === 1
                                            "
                                            class="flex justify-center mt-2"
                                        >
                                            <button
                                                @click="reportNoShow(booking)"
                                                class="cfn-btn px-5 py-2"
                                            >
                                                {{
                                                    $t("booking.report_noshow")
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-center">
                                        <img
                                            v-if="!booking.is_ourroom"
                                            class="h-14 w-auto"
                                            alt="platform logo"
                                            src="https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                                        />
                                        <img
                                            v-if="booking.is_ourroom"
                                            class="h-14 w-auto"
                                            alt="platform logo"
                                            src="https://site.flowtheroom.com/hotel/public/assets/img/ouroom%20logo-square%201.png"
                                        />
                                        <div
                                            class="text-blue-500"
                                            v-if="
                                                booking.state !== 0 &&
                                                !booking.distributor
                                            "
                                        >
                                            {{ booking.booking_id }} /
                                            {{ booking.confirmation_code }}
                                        </div>
                                        <div
                                            class="text-blue-500"
                                            v-if="
                                                booking.state !== 0 &&
                                                booking.distributor
                                            "
                                        >
                                            {{ booking.confirmation_code }} /
                                            {{ booking.booking_id }}
                                        </div>
                                        <div class="text-blue-500" v-else>
                                            TBC
                                        </div>
                                        <div class="flex items-center">
                                            <div
                                                class="
                                                    bg-red-400
                                                    text-white
                                                    rounded-md
                                                    px-2
                                                "
                                            >
                                                ${{ booking.book_price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="block lg:hidden">
                                    <div class="flex items-center">
                                        <span
                                            class="mr-2 text-3xl font-medium"
                                            >{{ index + 1 }}</span
                                        >{{ parseDate(booking.occupy_in) }} -
                                        {{ parseDate(booking.occupy_out) }}
                                    </div>
                                    <div class="mt-4">
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-user
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <circle cx="12" cy="7" r="4" />
                                                <path
                                                    d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                                /></svg
                                            >{{
                                                booking.given_name ||
                                                booking.surname
                                                    ? `${booking.given_name} ${booking.surname}`
                                                    : "User"
                                            }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-bed
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"
                                                />
                                                <circle
                                                    cx="7"
                                                    cy="10"
                                                    r="1"
                                                /></svg
                                            >{{ booking.name }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-calendar-time
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
                                                />
                                                <circle cx="18" cy="18" r="4" />
                                                <path d="M15 3v4" />
                                                <path d="M7 3v4" />
                                                <path d="M3 11h16" />
                                                <path
                                                    d="M18 16.496v1.504l1 1"
                                                /></svg
                                            >{{ booking.book_type_name }}
                                        </div>
                                        <div class="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-wallet
                                                    mr-2
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
                                                />
                                                <path
                                                    d="M20 12v4h-4a2 2 0 0 1 0 -4h4"
                                                />
                                            </svg>
                                            {{
                                                parsePaymentTime(
                                                    booking.payment_time
                                                )
                                            }}
                                        </div>
                                        <div class="my-3">
                                            <div
                                                class="flex justify-center"
                                                v-if="booking.state !== '1'"
                                            >
                                                <span
                                                    class="
                                                        text-center
                                                        px-5
                                                        py-2
                                                        rounded-md
                                                        shadow-md
                                                        bg-gray-500
                                                        text-white
                                                    "
                                                    >{{
                                                        getBookingState(
                                                            booking.state
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                v-if="
                                                    booking.report_no_show &&
                                                    booking.state === '1' &&
                                                    booking.refundable &&
                                                    mode &&
                                                    mode === 1
                                                "
                                                class="flex justify-center mt-2"
                                            >
                                                <button
                                                    @click="
                                                        reportNoShow(booking)
                                                    "
                                                    class="cfn-btn px-5 py-2"
                                                >
                                                    {{
                                                        $t(
                                                            "booking.report_noshow"
                                                        )
                                                    }}
                                                </button>
                                            </div>
                                        </div>

                                        <div class="flex items-center">
                                            <div
                                                v-if="
                                                    (booking.refund_min ||
                                                        booking.refund_min ===
                                                            0) &&
                                                    booking.refund_min >= 0
                                                "
                                            >
                                                <FreeCancel
                                                    class="text-green-600"
                                                />
                                            </div>
                                            <div v-else><NoCancel /></div>
                                            <div
                                                v-if="booking.promos"
                                                class="ml-1"
                                            >
                                                <button
                                                    @click="
                                                        upgradeIndex = index
                                                    "
                                                    class="
                                                        p-0.5
                                                        border border-orange-400
                                                        text-orange-400
                                                        hover:border-orange-300
                                                        hover:text-orange-300
                                                        shadow-md
                                                        rounded-md
                                                        transition
                                                        duration-200
                                                        ease-linear
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            stroke-current
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-arrow-up-circle
                                                        "
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#000000"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="9"
                                                        />
                                                        <line
                                                            x1="12"
                                                            y1="8"
                                                            x2="8"
                                                            y2="12"
                                                        />
                                                        <line
                                                            x1="12"
                                                            y1="8"
                                                            x2="12"
                                                            y2="16"
                                                        />
                                                        <line
                                                            x1="16"
                                                            y1="12"
                                                            x2="12"
                                                            y2="8"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="flex flex-col items-center">
                                            <img
                                                v-if="!booking.is_ourroom"
                                                class="h-12 w-auto"
                                                alt="flow logo"
                                                src="https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                                            />
                                            <img
                                                v-if="booking.is_ourroom"
                                                class="h-12 w-auto"
                                                alt="ourroom logo"
                                                src="https://site.flowtheroom.com/hotel/public/assets/img/ouroom%20logo-square%201.png"
                                            />
                                            <div
                                                class="text-blue-500"
                                                v-if="
                                                    booking.state !== 0 &&
                                                    !booking.distributor
                                                "
                                            >
                                                {{ booking.booking_id }} /
                                                {{ booking.confirmation_code }}
                                            </div>
                                            <div
                                                class="text-blue-500"
                                                v-if="
                                                    booking.state !== 0 &&
                                                    booking.distributor
                                                "
                                            >
                                                {{ booking.confirmation_code }}
                                                /
                                                {{ booking.booking_id }}
                                            </div>
                                            <div class="text-blue-500" v-else>
                                                TBC
                                            </div>
                                            <div
                                                class="
                                                    text-white
                                                    rounded-md
                                                    px-2
                                                "
                                                :class="{
                                                    'bg-red-400':
                                                        booking.state !== 2
                                                }"
                                            >
                                                ${{ booking.book_price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="booking.distributor"
                                    class="
                                        text-blue-600
                                        mt-3
                                        -mb-2
                                        lg:mt-0 lg:-my-2
                                    "
                                >
                                    ** This booking information has been
                                    automatically imported to hotel PMS.
                                </div>
                                <div
                                    v-if="booking.property_collect"
                                    class="
                                        text-red-700
                                        mt-3
                                        -mb-2
                                        lg:mt-0 lg:-my-2
                                    "
                                >
                                    ** This is a Property Collect booking.
                                    Please collect ${{
                                        booking.book_price.toFixed(2)
                                    }}
                                    from the guest directly upon check-in .
                                </div>
                            </div>
                            <div class="flex justify-center items-center">
                                <button
                                    @click="pageAction(0)"
                                    class="
                                        px-1
                                        rounded-l
                                        border border-gray-400
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon
                                            icon-tabler
                                            icon-tabler-chevron-left
                                        "
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#9e9e9e"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <polyline points="15 6 9 12 15 18" />
                                    </svg></button
                                ><span
                                    class="
                                        px-3
                                        border-gray-400 border-t border-b
                                    "
                                    >{{ currentPage }}</span
                                >
                                <button
                                    @click="pageAction(1)"
                                    class="
                                        px-1
                                        rounded-r
                                        border border-gray-400
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon
                                            icon-tabler
                                            icon-tabler-chevron-right
                                        "
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#9e9e9e"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <polyline points="9 6 15 12 9 18" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="displayType === 0 && isTableLoading"
                            class="
                                relative
                                flex
                                justify-center
                                items-center
                                py-8
                            "
                        >
                            <Loader :type="'lg'" />
                        </div>
                        <div
                            v-if="displayType === 1"
                            class="flex justify-between flex-wrap"
                        >
                            <div>
                                <input
                                    @change="getTimeRecord"
                                    :value="0"
                                    v-model="graphType"
                                    type="radio"
                                />
                                {{ $t("booking.past_30_per_day") }}
                            </div>
                            <div>
                                <input
                                    @change="getTimeRecord"
                                    :value="1"
                                    v-model="graphType"
                                    type="radio"
                                />
                                {{ $t("booking.past_30_per_interval") }}
                            </div>
                            <div>
                                <input
                                    @change="getTimeRecord"
                                    :value="2"
                                    v-model="graphType"
                                    type="radio"
                                />
                                {{ $t("booking.past_180_per_weekday") }}
                            </div>
                        </div>
                        <div v-if="displayType === 1 && !isChartLoading">
                            <div
                                id="chart"
                                class="
                                    flex
                                    md:justify-center
                                    mt-4
                                    h-80
                                    py-4
                                    overflow-x-scroll
                                "
                            ></div>
                        </div>
                        <div
                            v-if="displayType === 1 && isChartLoading"
                            class="
                                relative
                                flex
                                justify-center
                                items-center
                                py-8
                            "
                        >
                            <Loader :type="'lg'" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <Modal v-if="noShow"
            ><slot
                ><div>
                    <div class="mb-2">
                        <span
                            >Are you sure you want to report the booking as
                            no-show?</span
                        >
                        <div class="mt-1 text-sm text-red-600">
                            {{ $t("booking.noshow_remark") }}
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <button
                            @click="noShow = null"
                            class="
                                bg-red-400
                                text-gray-100
                                rounded-md
                                shadow-md
                                hover:bg-red-300 hover:text-white
                                transition
                                duration-500
                                ease-in-out;
                                px-4
                                py-2
                                mr-2
                            "
                        >
                            Cancel
                        </button>
                        <button
                            @click="submitNoShow"
                            class="cfn-btn px-4 py-2"
                            :class="{ 'filter grayscale': noshowBtn }"
                            :disabled="noshowBtn"
                        >
                            Confirm
                        </button>
                    </div>
                </div></slot
            ></Modal
        >
        <Modal v-if="upgradeIndex >= 0"
            ><slot
                ><div>
                    <h2 class="border-b border-gray-200 mb-4 text-xl">
                        Promotions:
                    </h2>
                    {{ bookings[upgradeIndex].promos.free_upgrade }}
                    <div class="flex mt-2">
                        <button
                            @click="upgradeIndex = -1"
                            class="cfn-btn px-4 py-2 w-full"
                            :class="{ 'filter grayscale': noshowBtn }"
                            :disabled="noshowBtn"
                        >
                            OK
                        </button>
                    </div>
                </div></slot
            ></Modal
        >
    </div>
</template>

<script>
import Loader from "@/components/Loader"
import { GoogleCharts } from "google-charts"
import Calendar from "@/components/Calendar"
import CalendarModal from "@/components/CalendarModal"
import Modal from "@/components/Modal"
import Legend from "@/components/Legend"
import FreeCancel from "@/components/Svg/FreeCancel"
import NoCancel from "@/components/Svg/NoCancel"

export default {
    name: "PastBooking",
    components: {
        NoCancel,
        FreeCancel,
        Legend,
        Modal,
        CalendarModal,
        Calendar,
        Loader
    },
    props: {
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number },
        mode: { required: false, type: Number }
    },
    data() {
        return {
            isLoading: true,
            isTableLoading: true,
            isChartLoading: true,
            displayType: 0,
            graphType: 0,
            distributionType: "all",
            timePeriod: 0,
            currentPage: 1,
            noShow: null,
            startDate: this.getToday(),
            startCalendar: false,
            endDate: this.getToday(),
            endCalendar: false,
            order: { type: "occupy_in", ascending: true },
            roomType: "all",
            bookType: "all",
            noshowBtn: false,
            upgradeIndex: -1,
            bookings: [
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 0,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: null,
                //     booking_id: null,
                //     price: 210
                // },
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 1,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: "48ET",
                //     booking_id: "30914235",
                //     price: 210
                // },
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 2,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: "48ET",
                //     booking_id: "30914235",
                //     price: 210
                // }
            ]
        }
    },
    methods: {
        getBookingState(num) {
            num = parseInt(num)
            switch (num) {
                case 1:
                    return "Confirmed"
                case 3:
                case 4:
                    return this.$t("booking.rejected")
                case 9:
                    return this.$t("booking.cancelled")
                case 12:
                    return "No show"
            }
        },
        getBookType(string) {
            string = string.toLowerCase()
            switch (string) {
                case "hour":
                    return "Flexible Session"
                case "session":
                    return "Session"
                case "overnight":
                    return "Overnight"
                case "hourly":
                    return "Hourly"
                case "day":
                    return "Day Pass"
            }
        },
        async getBookings() {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/pastbooking/getPastBooking/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&lang=${this.$i18n.locale}&time_period=${this.timePeriod}${
                this.timePeriod === 5 &&
                this.startDate !== "" &&
                this.endDate !== ""
                    ? `&period_start=${this.startDate.replaceAll(
                          "/",
                          "-"
                      )}&period_end=${this.endDate.replaceAll("/", "-")}`
                    : ""
            }&is_ascending=${this.order.ascending ? "0" : "1"}&order=${
                this.order.type ? this.order.type : "occupy_in"
            }&mode=1&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }&page_size=50&current_page=${this.currentPage}${
                this.roomType !== "all" ? `&room_type=${this.roomType}` : ""
            }${this.bookType !== "all" ? `&book_type=${this.bookType}` : ""}${
                this.distributionType !== "all"
                    ? `&distributor=${this.distributionType}`
                    : ""
            }`
            this.isTableLoading = true
            const res = await this.$axios.get(url)
            this.bookings = res.data.data
            this.isTableLoading = false
        },
        updateFilter() {
            if (this.timePeriod !== 5) {
                this.getBookings()
            } else if (this.startDate && this.endDate) {
                this.getBookings()
            }
        },
        async getTimeRecord() {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/pastBooking/getTimePeriodReservation/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?type=${this.graphType}&space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }`
            this.isChartLoading = true
            const res = await this.$axios.get(url)
            this.graphData = res.data.data
            if (this.displayType === 1) {
                this.drawChart(this.graphType)
            }
            this.isChartLoading = false
        },
        drawChart(type) {
            this.displayType = 1
            let gData = this.graphData
            let title = this.$t("booking.number_bookings")
            if (type === 0) {
                GoogleCharts.load(
                    function () {
                        {
                            // Standard google charts functionality is available as GoogleCharts.api after load
                            const data =
                                new GoogleCharts.api.visualization.DataTable()
                            data.addColumn("date", "Date")
                            data.addColumn("number", title)

                            let rows = []
                            let max = gData[Object.keys(gData)[0]]
                            for (let i in gData) {
                                if (gData[i] > max) {
                                    max = gData[i]
                                }
                                rows.push([new Date(i), gData[i]])
                            }
                            data.addRows(rows)
                            const chart =
                                new GoogleCharts.api.visualization.ColumnChart(
                                    document.getElementById("chart")
                                )
                            let options = {
                                legend: {
                                    position: "top",
                                    alignment: "center"
                                },
                                vAxis: {
                                    viewWindow: {
                                        max: max * 2
                                    }
                                }
                            }
                            chart.draw(data, options)
                            let timer
                            window.addEventListener("resize", () => {
                                clearTimeout(timer)
                                timer = setTimeout(
                                    chart.draw(data, options),
                                    200
                                )
                            })
                        }
                    },
                    { packages: ["corechart", "bar"] }
                )
            } else if (type === 1) {
                GoogleCharts.load(
                    function () {
                        {
                            // Standard google charts functionality is available as GoogleCharts.api after load
                            const data =
                                new GoogleCharts.api.visualization.DataTable()
                            data.addColumn("string", "Time")
                            data.addColumn("number", "Number of bookings")

                            let rows = []
                            let max = gData[Object.keys(gData)[0]]
                            for (let i in gData) {
                                if (gData[i] > max) {
                                    max = gData[i]
                                }
                                rows.push([i, gData[i]])
                            }
                            data.addRows(rows)
                            const chart =
                                new GoogleCharts.api.visualization.ColumnChart(
                                    document.getElementById("chart")
                                )
                            let options = {
                                legend: {
                                    position: "top",
                                    alignment: "center"
                                },
                                vAxis: {
                                    viewWindow: {
                                        max: max * 2
                                    }
                                }
                            }
                            chart.draw(data, options)
                            let timer
                            window.addEventListener("resize", () => {
                                clearTimeout(timer)
                                timer = setTimeout(
                                    chart.draw(data, options),
                                    200
                                )
                            })
                        }
                    },
                    { packages: ["corechart", "bar"] }
                )
            } else {
                GoogleCharts.load(
                    function () {
                        {
                            // Standard google charts functionality is available as GoogleCharts.api after load
                            const data =
                                new GoogleCharts.api.visualization.DataTable()
                            data.addColumn("string", "Day of the week")
                            data.addColumn("number", "Number of bookings")

                            let rows = []
                            let max = gData[Object.keys(gData)[0]]
                            for (let i in gData) {
                                if (gData[i] > max) {
                                    max = gData[i]
                                }
                                rows.push([i, gData[i]])
                            }
                            data.addRows(rows)
                            const chart =
                                new GoogleCharts.api.visualization.ColumnChart(
                                    document.getElementById("chart")
                                )
                            let options = {
                                legend: {
                                    position: "top",
                                    alignment: "center"
                                },
                                vAxis: {
                                    viewWindow: {
                                        max: max * 2
                                    }
                                }
                            }
                            chart.draw(data, options)
                            let timer
                            window.addEventListener("resize", () => {
                                clearTimeout(timer)
                                timer = setTimeout(
                                    chart.draw(data, options),
                                    200
                                )
                            })
                        }
                    },
                    { packages: ["corechart", "bar"] }
                )
            }
        },
        parseDate(time) {
            const d = new Date(time.replaceAll(" ", "T"))
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            let hour = d.getHours()
            let minute = d.getMinutes()
            month < 10 ? (month = "0" + month) : month
            date < 10 ? (date = "0" + date) : date
            hour < 10 ? (hour = "0" + hour) : hour
            minute < 10 ? (minute = "0" + minute) : minute
            return `${year}-${month}-${date} ${hour}:${minute}`
        },
        parsePaymentTime(time) {
            const d = new Date(time)
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            let hour = d.getHours()
            let minute = d.getMinutes()
            month < 10 ? (month = "0" + month) : month
            date < 10 ? (date = "0" + date) : date
            hour < 10 ? (hour = "0" + hour) : hour
            minute < 10 ? (minute = "0" + minute) : minute
            return `${year}-${month}-${date} ${hour}:${minute}`
        },
        canNoShow(time) {
            let originTime = new Date(time).getTime()
            let OneDay = new Date(time).getTime() + 2 * 24 * 60 * 60 * 1000
            let accessTime = new Date(this.user.access_time).getTime()
            if (OneDay >= accessTime && accessTime >= originTime) {
                return true
            }
        },
        async reportNoShow(booking) {
            if (this.mode === 2) return alert("Not enabled for party room yet.")
            this.noShow = {
                reserve_id: booking.reserve_id,
                transaction_id: booking.transaction_id,
                space_id:
                    this.user.space_info_list[this.selectedSpace].space_id,
                space_type:
                    this.user.space_info_list[this.selectedSpace].space_type,
                country_code: booking.country_code
            }
        },
        async submitNoShow() {
            if (this.mode === 2) {
                return alert("Not enabled for party room yet.")
            }
            this.noshowBtn = true
            const url = `${process.env.VUE_APP_API_BASE_URL}/pastBooking/noShow`
            await this.$axios
                .put(url, this.noShow)
                .then((r) => {
                    if (r.data.Success) {
                        this.noShow = null
                        this.getBookings()
                        this.noshowBtn = false
                        alert("Success")
                    } else this.noshowBtn = false
                })
                .catch((error) => {
                    this.noshowBtn = false
                    if (error.response) {
                        // Request made and server responded
                        alert(error.response.data.data.err_message)
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        },
        pageAction(action) {
            if (action === 0) {
                if (this.currentPage > 1) {
                    this.currentPage--
                    this.getBookings()
                }
            }
            if (action === 1) {
                this.currentPage++
                this.getBookings()
            }
        },
        getToday() {
            let d = new Date()
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            month < 10 ? (month = "0" + month) : month
            let date = d.getDate()
            date < 10 ? (date = "0" + date) : date
            return `${year}-${month}-${date}`
        },
        selectStartDate(val) {
            this.startDate = val
            this.startDate > this.endDate ? (this.endDate = val) : null
            this.getBookings()
        },
        selectEndDate(val) {
            this.endDate = val
            this.getBookings()
        },
        changeDistribution() {
            this.saveDistributionType()
            this.getBookings()
        },
        saveDistributionType() {
            return localStorage.setItem(
                "Past-Distribution",
                this.distributionType
            )
        },
        getDistributionType() {
            return localStorage.getItem("Past-Distribution")
        }
    },
    async mounted() {
        let distribution = this.getDistributionType()
        if (distribution) {
            this.distributionType = distribution
        }
        await this.getTimeRecord()
        await this.getBookings()
        this.isLoading = false
    },
    watch: {
        selectedSpace(to, from) {
            this.getBookings()
            this.getTimeRecord()
        }
    }
}
</script>

<style scoped></style>
