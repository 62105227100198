<template>
    <div
        id="container"
        :class="{ 'bg-white p-4 rounded-md mt-4 shadow-md': hasContainer }"
    >
        <div
            @click="toggleShow"
            class="flex justify-between items-center mb-2"
            :class="{ 'border-b': isShow, 'cursor-pointer': hasContainer }"
        >
            <h3 class="font-medium text-left text-xl">
                Audit Log <span class="uppercase">({{ type }})</span>
            </h3>
            <button v-if="hasContainer">
                <svg
                    v-if="!isShow"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-caret-down-filled"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                        stroke-width="0"
                        fill="currentColor"
                    />
                </svg>
                <svg
                    v-if="isShow"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-caret-up-filled"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M11.293 7.293a1 1 0 0 1 1.32 -.083l.094 .083l6 6l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059l-.002 .059l-.005 .058l-.009 .06l-.01 .052l-.032 .108l-.027 .067l-.07 .132l-.065 .09l-.073 .081l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002h-12c-.852 0 -1.297 -.986 -.783 -1.623l.076 -.084l6 -6z"
                        stroke-width="0"
                        fill="currentColor"
                    />
                </svg>
            </button>
        </div>
        <div v-if="isShow">
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <label
                        class="
                            block
                            text-sm text-left
                            font-medium
                            leading-6
                            text-gray-900
                        "
                        >Room Type
                    </label>
                    <div class="mt-2">
                        <v-select
                            v-model="searchParams.venue_type"
                            :options="venueTypes"
                            :reduce="(venue) => venue.venue_type"
                            label="name"
                            multiple
                            class="
                                inline-block
                                appearance-none
                                w-full
                                rounded-md
                            "
                            placeholder="Please select a room type"
                            @option:selecting="saveSelectedOption"
                            @option:selected="
                                filterRoomType(selectedVenueOption)
                            "
                        >
                        </v-select>
                    </div>
                </div>
                <div>
                    <label
                        class="
                            block
                            text-sm text-left
                            font-medium
                            leading-6
                            text-gray-900
                        "
                        >Time Slot
                    </label>
                    <div class="mt-2">
                        <select
                            v-model="searchParams.book_ids"
                            class="input-with-label"
                        >
                            <option value="">All</option>
                            <option
                                v-for="(timeslot, index) in timeSlots"
                                :key="index"
                                :value="timeslot.book_ids"
                            >
                                {{
                                    timeslot.book_type === "hour" ||
                                    timeslot.book_type === "hourly"
                                        ? formateDuration(timeslot.duration_min)
                                        : `${formateTime(timeslot.start_time)} -
                                              ${formateTime(timeslot.end_time)}`
                                }}
                                ({{
                                    timeslot.refundable
                                        ? "Refundable"
                                        : "Non-refundable"
                                }})
                            </option>
                        </select>
                    </div>
                </div>
                <div>
                    <label
                        class="
                            block
                            text-sm text-left
                            font-medium
                            leading-6
                            text-gray-900
                        "
                        >Book Type
                    </label>
                    <div class="mt-2">
                        <select
                            v-model="searchParams.book_type"
                            class="input-with-label"
                        >
                            <option value="">All</option>
                            <option
                                v-for="(
                                    booktype, index
                                ) in space.book_type_list"
                                :key="index"
                                :value="booktype"
                            >
                                {{ formateBookType(booktype) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div>
                    <label
                        class="
                            block
                            text-sm text-left
                            font-medium
                            leading-6
                            text-gray-900
                        "
                        >Distribution Type
                    </label>
                    <div class="mt-2">
                        <select
                            v-model="searchParams.distributor"
                            class="input-with-label"
                        >
                            <option value="">All</option>
                            <option value="flow">Flow</option>
                            <option value="external">External</option>
                        </select>
                    </div>
                </div>
                <div
                    v-if="showCalendar"
                    class="col-span-2 bg-gray-300 rounded-md"
                >
                    <Calendar
                        class="py-2"
                        :selected-dates="searchParams.dates"
                        @onSelect="selectDate"
                    />
                </div>
                <div
                    class="
                        col-span-2
                        grid grid-cols-2
                        gap-4
                        md:flex md:justify-end
                    "
                >
                    <button
                        v-if="showCalendar"
                        @click="searchParams.dates = []"
                        class="cancel-btn px-2 py-1.5"
                    >
                        Clear All Dates
                    </button>
                    <button
                        @click="getAuditLog(type)"
                        class="cfn-btn px-2 py-1.5"
                    >
                        Search
                    </button>
                </div>
                <div class="border-b col-span-2"></div>
            </div>
            <div v-if="type === 'availability'" class="mt-4 overflow-x-scroll">
                <!--                <table class="table-auto min-w-full divide-gray-600">-->
                <!--                    <thead>-->
                <!--                        <tr>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                    rounded-l-md-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Update Time (DD/MM)-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Room Type-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Book Type-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    px-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Time Slot-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    px-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    rounded-r-md-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Allotment-->
                <!--                            </th>-->
                <!--                        </tr>-->
                <!--                    </thead>-->
                <!--                    <tbody v-if="!isLoading">-->
                <!--                        <tr-->
                <!--                            class="-->
                <!--                                text-left text-sm-->
                <!--                                desktop:text-base-->
                <!--                                hover:bg-gray-100-->
                <!--                                transition-->
                <!--                                ease-linear-->
                <!--                                duration-200-->
                <!--                            "-->
                <!--                        >-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div-->
                <!--                                    title="abc@flowtheroom.com"-->
                <!--                                    class="flex items-center"-->
                <!--                                >-->
                <!--                                    <img-->
                <!--                                        class="h-8 w-auto"-->
                <!--                                        :src="-->
                <!--                                            require('@/assets/flow_small_logo.png')-->
                <!--                                        "-->
                <!--                                        alt="logo"-->
                <!--                                    />-->
                <!--                                    01/11 11:49-->
                <!--                                </div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>Superior Room</div>-->
                <!--                                <div>Deluxe Room</div>-->
                <!--                                <div>Deluxe Room</div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>Session</div>-->
                <!--                                <div>Session</div>-->
                <!--                                <div>Session</div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div class="flex text-green-600">-->
                <!--                                    <FreeCancel /> 1-Hour Prior-->
                <!--                                </div>-->
                <!--                                <div>11:00 - 17:00</div>-->
                <!--                                <div class="flex text-green-600">-->
                <!--                                    <FreeCancel /> Anytime-->
                <!--                                </div>-->
                <!--                                <div>14:00 - 20:00</div>-->
                <!--                                <div class="flex">-->
                <!--                                    <NoCancel />-->
                <!--                                    <div>11:00 - 17:00</div>-->
                <!--                                </div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>2</div>-->
                <!--                                <div>0</div>-->
                <!--                                <div>0</div>-->
                <!--                            </td>-->
                <!--                        </tr>-->
                <!--                    </tbody>-->
                <!--                </table>-->
                <div
                    v-for="(log, index) in auditLog"
                    :key="index"
                    class="
                        grid grid-cols-5
                        px-4
                        text-left text-sm
                        desktop:text-base
                        hover:bg-gray-100
                        transition
                        ease-linear
                        duration-200
                    "
                >
                    <div v-if="index === 0" class="col-span-5 grid grid-cols-5">
                        <div
                            class="
                                whitespace-nowrap
                                -ml-4
                                pl-4
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                                rounded-l-md
                            "
                        >
                            Update Time (DD/MM)
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Room Type
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Book Type
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Time Slot
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                -mr-4
                                py-2
                                bg-gray-200
                                rounded-r-md
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Allotment
                        </div>
                    </div>
                    <div>
                        <div
                            v-if="log.user.type === 'flow'"
                            class="flex items-center"
                        >
                            <img
                                class="h-8 w-auto"
                                :src="require('@/assets/flow_small_logo.png')"
                                alt="logo"
                            />
                            {{ formateUpdateTime(log.timestamp) }}
                        </div>
                        <div
                            v-else
                            class="flex items-center"
                            :title="log.user.email"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-user
                                "
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path
                                    d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                />
                            </svg>
                            {{ formateUpdateTime(log.timestamp) }}
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div
                            v-for="(item, idx) in [
                                ...new Set(
                                    log.action.modified_items.map(
                                        (i) => i.venue_type
                                    )
                                )
                            ]"
                            :key="idx"
                            class="grid grid-cols-4"
                        >
                            <div>{{ formateVenueType(item) }}</div>
                            <div class="col-span-3">
                                <div
                                    v-for="(i, idx1) in [
                                        ...new Set(
                                            log.action.modified_items
                                                .filter(
                                                    (e) => e.venue_type === item
                                                )
                                                .map((x) => x.book_type)
                                        )
                                    ]"
                                    :key="idx1"
                                    class="grid grid-cols-3"
                                >
                                    <div>
                                        {{ formateBookType(i) }}
                                    </div>
                                    <div class="col-span-2">
                                        <div
                                            v-for="(
                                                slot, idx2
                                            ) in log.action.modified_items.filter(
                                                (e) =>
                                                    e.venue_type === item &&
                                                    e.book_type === i
                                            )"
                                            :key="idx2"
                                            class="grid grid-cols-2"
                                        >
                                            <div
                                                v-for="(
                                                    timeslot, idx3
                                                ) in timeSlots.filter((e) =>
                                                    e.book_ids.includes(
                                                        slot.available_book_id
                                                    )
                                                )"
                                                :key="idx3"
                                            >
                                                <div
                                                    v-if="timeslot.refundable"
                                                    class="
                                                        flex
                                                        items-center
                                                        text-xs
                                                        desktop:text-sm
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            items-center
                                                            text-green-600
                                                            mr-1
                                                        "
                                                    >
                                                        <FreeCancel
                                                            class="mr-1"
                                                        />
                                                        {{
                                                            formateRefundMin(
                                                                timeslot.advance_refund_min
                                                            )
                                                        }}
                                                    </div>
                                                    <span
                                                        v-if="
                                                            timeslot.book_type ===
                                                            'hour'
                                                        "
                                                        class="text-subtlegray"
                                                    >
                                                        {{
                                                            formateDuration(
                                                                timeslot.duration_min
                                                            )
                                                        }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="text-subtlegray"
                                                    >
                                                        {{
                                                            formateTime(
                                                                timeslot.start_time
                                                            )
                                                        }}-{{
                                                            formateTime(
                                                                timeslot.end_time
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div
                                                    v-else
                                                    class="
                                                        flex
                                                        items-center
                                                        text-xs
                                                        desktop:text-sm
                                                    "
                                                >
                                                    <NoCancel
                                                        class="text-red-500"
                                                    /><span
                                                        v-if="
                                                            timeslot.book_type ===
                                                            'hour'
                                                        "
                                                        class="text-subtlegray"
                                                    >
                                                        {{
                                                            formateDuration(
                                                                timeslot.duration_min
                                                            )
                                                        }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="text-subtlegray"
                                                    >
                                                        {{
                                                            formateTime(
                                                                timeslot.start_time
                                                            )
                                                        }}-{{
                                                            formateTime(
                                                                timeslot.end_time
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="
                                                        text-xs
                                                        desktop:text-sm
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            timeslot.book_type ===
                                                            'hour'
                                                        "
                                                    >
                                                        {{
                                                            formateUpdateTime(
                                                                slot.start
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            formateUpdateTime(
                                                                slot.end
                                                            )
                                                        }}
                                                    </span>
                                                    <span v-else>
                                                        {{
                                                            formateUpdateDate(
                                                                slot.start_date
                                                            )
                                                        }}
                                                        -
                                                        {{
                                                            formateUpdateDate(
                                                                slot.end_date
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    timeSlots.filter((e) =>
                                                        e.book_ids.includes(
                                                            slot.available_book_id
                                                        )
                                                    ).length === 0
                                                "
                                            ></div>
                                            <div>{{ slot.availability }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="type === 'pricing'" class="overflow-x-scroll">
                <!--                <table class="table-auto min-w-full divide-gray-600">-->
                <!--                    <thead>-->
                <!--                        <tr>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                    rounded-l-md-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Update Time-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Priority-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Date(s)-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Room Type-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    pl-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Book Type-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    px-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Time Slot-->
                <!--                            </th>-->
                <!--                            <th-->
                <!--                                class="-->
                <!--                                    whitespace-nowrap-->
                <!--                                    px-4-->
                <!--                                    py-2-->
                <!--                                    bg-gray-200-->
                <!--                                    rounded-r-md-->
                <!--                                    text-left text-sm-->
                <!--                                    desktop:text-base-->
                <!--                                    font-medium-->
                <!--                                    text-gray-500-->
                <!--                                    tracking-wider-->
                <!--                                "-->
                <!--                            >-->
                <!--                                Price-->
                <!--                            </th>-->
                <!--                        </tr>-->
                <!--                    </thead>-->
                <!--                    <tbody v-if="!isLoading">-->
                <!--                        <tr-->
                <!--                            class="-->
                <!--                                text-left-->
                <!--                                whitespace-nowrap-->
                <!--                                text-sm-->
                <!--                                desktop:text-base-->
                <!--                                hover:bg-gray-100-->
                <!--                                transition-->
                <!--                                ease-linear-->
                <!--                                duration-200-->
                <!--                            "-->
                <!--                        >-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div-->
                <!--                                    title="abc@flowtheroom.com"-->
                <!--                                    class="flex items-center"-->
                <!--                                >-->
                <!--                                    <img-->
                <!--                                        class="h-8 w-auto"-->
                <!--                                        :src="-->
                <!--                                            require('@/assets/flow_small_logo.png')-->
                <!--                                        "-->
                <!--                                        alt="logo"-->
                <!--                                    />-->
                <!--                                    01/11 14:02-->
                <!--                                </div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>Specific Date</div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div class="flex flex-wrap">-->
                <!--                                    <div-->
                <!--                                        class="-->
                <!--                                            text-white text-sm-->
                <!--                                            rounded-full-->
                <!--                                            px-2-->
                <!--                                            py-1-->
                <!--                                            ml-2-->
                <!--                                            transition-->
                <!--                                            mb-1.5-->
                <!--                                            duration-200-->
                <!--                                            ease-linear-->
                <!--                                            bg-yellow-400-->
                <!--                                        "-->
                <!--                                    >-->
                <!--                                        2023-11-16-->
                <!--                                    </div>-->
                <!--                                    <div-->
                <!--                                        class="-->
                <!--                                            text-white text-sm-->
                <!--                                            rounded-full-->
                <!--                                            px-2-->
                <!--                                            py-1-->
                <!--                                            ml-2-->
                <!--                                            transition-->
                <!--                                            mb-1.5-->
                <!--                                            duration-200-->
                <!--                                            ease-linear-->
                <!--                                            bg-yellow-400-->
                <!--                                        "-->
                <!--                                    >-->
                <!--                                        2023-11-17-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>Superior Room</div>-->
                <!--                                <div>Deluxe Room</div>-->
                <!--                                <div>Deluxe Room</div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>Session</div>-->
                <!--                                <div>Session</div>-->
                <!--                                <div>Session</div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div class="flex text-green-600">-->
                <!--                                    <FreeCancel /> 1-Hour Prior-->
                <!--                                </div>-->
                <!--                                <div>11:00 - 17:00</div>-->
                <!--                                <div class="flex text-green-600">-->
                <!--                                    <FreeCancel /> Anytime-->
                <!--                                </div>-->
                <!--                                <div>14:00 - 20:00</div>-->
                <!--                                <div class="flex">-->
                <!--                                    <NoCancel />-->
                <!--                                    <div>11:00 - 17:00</div>-->
                <!--                                </div>-->
                <!--                            </td>-->
                <!--                            <td class="pl-4 py-3">-->
                <!--                                <div>HKD200</div>-->
                <!--                                <div>HKD250</div>-->
                <!--                                <div>HKD240</div>-->
                <!--                            </td>-->
                <!--                        </tr>-->
                <!--                    </tbody>-->
                <!--                </table>-->
                <div
                    v-for="(log, index) in auditLog"
                    :key="index"
                    class="
                        grid grid-cols-7
                        px-4
                        text-left text-sm
                        desktop:text-base
                        hover:bg-gray-100
                        transition
                        ease-linear
                        duration-200
                    "
                >
                    <div v-if="index === 0" class="col-span-7 grid grid-cols-7">
                        <div
                            class="
                                whitespace-nowrap
                                -ml-4
                                pl-4
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                                rounded-l-md
                            "
                        >
                            Update Time
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Priority
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Date(s)
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Room Type
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Book Type
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                py-2
                                bg-gray-200
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Time Slot
                        </div>
                        <div
                            class="
                                whitespace-nowrap
                                -mr-4
                                py-2
                                bg-gray-200
                                rounded-r-md
                                text-left text-sm
                                desktop:text-base
                                font-medium
                                text-gray-500
                                tracking-wider
                            "
                        >
                            Price
                        </div>
                    </div>
                    <div>
                        <div
                            v-if="log.user.type === 'flow'"
                            class="flex items-center"
                        >
                            <img
                                class="h-8 w-auto"
                                :src="require('@/assets/flow_small_logo.png')"
                                alt="logo"
                            />
                            {{ formateUpdateTime(log.timestamp) }}
                        </div>
                        <div
                            v-else
                            class="flex items-center"
                            :title="log.user.email"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-user
                                "
                                width="32"
                                height="32"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path
                                    d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                />
                            </svg>
                            {{ formateUpdateTime(log.timestamp) }}
                        </div>
                    </div>
                    <div>
                        {{ formatePricePriority(log.action.modified_type) }}
                    </div>
                    <div>
                        <div
                            v-if="log.action.dates.type === 'range'"
                            class="flex flex-wrap"
                        >
                            <span
                                class="
                                    text-white text-xs
                                    rounded-full
                                    px-2
                                    py-1
                                    transition
                                    mb-1.5
                                    duration-200
                                    ease-linear
                                    bg-yellow-400
                                "
                                >{{ log.action.dates.start }}</span
                            >to<span
                                class="
                                    text-white text-xs
                                    rounded-full
                                    px-2
                                    py-1
                                    transition
                                    mb-1.5
                                    duration-200
                                    ease-linear
                                    bg-yellow-400
                                "
                                >{{ log.action.dates.end }}</span
                            >
                        </div>
                        <div
                            v-else
                            v-for="(date, index) in log.action.dates.dates"
                            :key="index"
                            class="flex flex-wrap"
                        >
                            <span
                                class="
                                    text-white text-xs
                                    rounded-full
                                    px-2
                                    py-1
                                    transition
                                    mb-1.5
                                    duration-200
                                    ease-linear
                                    bg-yellow-400
                                "
                                >{{ date }}</span
                            >
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div
                            v-for="(item, idx) in [
                                ...new Set(
                                    log.action.modified_items.map(
                                        (i) => i.venue_type
                                    )
                                )
                            ]"
                            :key="idx"
                            class="grid grid-cols-4"
                        >
                            <div>{{ formateVenueType(item) }}</div>
                            <div class="col-span-3">
                                <div
                                    v-for="(i, idx1) in [
                                        ...new Set(
                                            log.action.modified_items
                                                .filter(
                                                    (e) => e.venue_type === item
                                                )
                                                .map((x) => x.book_type)
                                        )
                                    ]"
                                    :key="idx1"
                                    class="grid grid-cols-3"
                                >
                                    <div>
                                        {{ formateBookType(i) }}
                                    </div>
                                    <div class="col-span-2">
                                        <div
                                            v-for="(
                                                slot, idx2
                                            ) in log.action.modified_items.filter(
                                                (e) =>
                                                    e.venue_type === item &&
                                                    e.book_type === i
                                            )"
                                            :key="idx2"
                                            class="grid grid-cols-2"
                                        >
                                            <div
                                                v-for="(
                                                    timeslot, idx3
                                                ) in timeSlots.filter((e) =>
                                                    e.book_ids.includes(
                                                        slot.available_book_id
                                                    )
                                                )"
                                                :key="idx3"
                                            >
                                                <div v-if="timeslot.refundable">
                                                    <div
                                                        class="
                                                            flex
                                                            items-center
                                                            text-green-600
                                                        "
                                                    >
                                                        <FreeCancel
                                                            class="mr-1"
                                                        />
                                                        {{
                                                            formateRefundMin(
                                                                timeslot.advance_refund_min
                                                            )
                                                        }}
                                                    </div>
                                                    <div>
                                                        <span
                                                            v-if="
                                                                timeslot.book_type ===
                                                                'hour'
                                                            "
                                                        >
                                                            {{
                                                                formateDuration(
                                                                    timeslot.duration_min
                                                                )
                                                            }}
                                                        </span>
                                                        <span v-else>
                                                            {{
                                                                formateTime(
                                                                    timeslot.start_time
                                                                )
                                                            }}-{{
                                                                formateTime(
                                                                    timeslot.end_time
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else
                                                    class="flex items-center"
                                                >
                                                    <NoCancel
                                                        class="text-red-500"
                                                    /><span
                                                        v-if="
                                                            timeslot.book_type ===
                                                            'hour'
                                                        "
                                                    >
                                                        {{
                                                            formateDuration(
                                                                timeslot.duration_min
                                                            )
                                                        }}
                                                    </span>
                                                    <span v-else>
                                                        {{
                                                            formateTime(
                                                                timeslot.start_time
                                                            )
                                                        }}-{{
                                                            formateTime(
                                                                timeslot.end_time
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    timeSlots.filter((e) =>
                                                        e.book_ids.includes(
                                                            slot.available_book_id
                                                        )
                                                    ).length === 0
                                                "
                                            ></div>
                                            <div>
                                                <div
                                                    v-if="
                                                        log.action.metadata
                                                            .weekday_spec
                                                    "
                                                    class="mb-1"
                                                >
                                                    <span
                                                        v-for="(
                                                            day, index
                                                        ) in log.action.metadata
                                                            .weekday_spec[
                                                            slot.day_type
                                                        ]"
                                                        :key="index"
                                                        >{{
                                                            index > 0
                                                                ? ", "
                                                                : ""
                                                        }}{{ days[day] }}</span
                                                    >
                                                </div>
                                                {{ slot.currency + slot.price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!isLoading && auditLog.length === 0"
                class="flex justify-center items-center p-4"
            >
                No Results Found
            </div>
            <div
                v-if="isLoading"
                class="relative flex justify-center items-center py-4"
            >
                <Loader type="lg" />
            </div>
            <Pagination
                :current-page="searchParams.pageNum"
                @prevPage="pageButtonAction('prev')"
                @nextPage="pageButtonAction('next')"
                @specificPage="toSpecificPage"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, reactive, watch, defineExpose } from "vue"
import Pagination from "@/components/Pagination"
import FreeCancel from "@/components/Svg/FreeCancel"
import NoCancel from "@/components/Svg/NoCancel"
import Loader from "@/components/Loader"
import axios from "axios"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import Calendar from "@/components/Calendar"
import i18n from "@/plugins/i18n"
import { useI18n } from "vue-i18n"

const i18nLocale = useI18n()

const props = defineProps({
    user: { required: true, type: Object },
    date: { required: false, type: String },
    selectedSpace: { required: true, type: Number },
    hasContainer: { required: false, type: Boolean },
    type: { required: true, type: String },
    showCalendar: Boolean
})

defineExpose({
    getAuditLog
})

let space = ref({})
let days = ref(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])

let isShow = ref(false)
let isLoading = ref(true)

let selectedVenueOption = ref({})

let searchParams = ref({
    venue_type: [""],
    book_type: "",
    book_ids: "",
    distributor: "",
    dates: [],
    pageNum: 1
})

let venueTypes = ref([])
let timeSlots = ref([])
let auditLog = ref([])

onMounted(() => {
    space.value = props.user.space_info_list[props.selectedSpace]
    if (!props.hasContainer) {
        isShow.value = true
    }
    if (!props.hasContainer) {
        getVenueType()
        getTimeSlot()
        getAuditLog(props.type)
    }
})

function toggleShow() {
    if (!props.hasContainer) {
        return null
    }
    isShow.value = !isShow.value
    setTimeout(() => {
        let element = document.getElementById("container")
        element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
        })
    }, 10)
    if (isShow.value) {
        getVenueType()
        getTimeSlot()
        getAuditLog(props.type)
    }
}

function saveSelectedOption(val) {
    selectedVenueOption.value = val
}

function filterRoomType(val) {
    let idx = searchParams.value.venue_type.indexOf("")
    if (idx > -1 && val.venue_type !== "") {
        searchParams.value.venue_type.splice(0, 1)
    }
    if (val.venue_type === "") {
        searchParams.value.venue_type = [""]
    }
}

async function selectDate(val) {
    let date = `${val.year}-${val.month > 9 ? val.month : "0" + val.month}-${
        val.date > 9 ? val.date : "0" + val.date
    }`
    if (searchParams.value.dates.includes(date)) {
        searchParams.value.dates.splice(
            searchParams.value.dates.indexOf(date),
            1
        )
    } else searchParams.value.dates.push(date)
    searchParams.value.dates.sort()
}

async function getVenueType() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/utils/venueTypes/${space.value.space_id}?lang=${i18nLocale.locale.value}`
    const res = await axios.get(url).catch((error) => {
        if (error.response) {
            // Request made and server responded
            alert(error.response.data.data.err_message)
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    })
    venueTypes.value = res.data.data.list
    venueTypes.value.unshift({ venue_type: "", name: "All" })
}

async function getTimeSlot() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/utils/timeslots/${space.value.space_id}`
    const res = await axios.get(url).catch((error) => {
        if (error.response) {
            // Request made and server responded
            alert(error.response.data.data.err_message)
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    })
    timeSlots.value = res.data.data.list
}

function auditLogFilter() {
    let filter = ""
    if (
        props.type === "availability" ||
        (props.type === "pricing" && !props.showCalendar)
    ) {
        filter += `&f_dates=${props.date}`
    }
    if (
        props.type === "pricing" &&
        props.showCalendar &&
        searchParams.value.dates.length > 0
    ) {
        filter += `&f_dates=${searchParams.value.dates.toString()}`
    }
    if (
        searchParams.value.venue_type.length > 0 &&
        !searchParams.value.venue_type.includes("")
    ) {
        filter += `&f_venue_types=${searchParams.value.venue_type.toString()}`
    }
    if (searchParams.value.book_ids) {
        filter += `&f_book_ids=${searchParams.value.book_ids.toString()}`
    }
    if (searchParams.value.book_type) {
        filter += `&f_book_type=${searchParams.value.book_type}`
    }
    if (searchParams.value.distributor) {
        filter += `&f_distributor=${searchParams.value.distributor}`
    }
    return filter
}

async function getAuditLog(type) {
    auditLog.value = []
    isLoading.value = true
    if (type === "availability" && props.date) {
        const url = `${
            process.env.VUE_APP_API_BASE_URL
        }/auditLog/availability/${space.value.space_id}?space_type=${
            space.value.space_type
        }&country_code=${space.value.country_code}&page=${
            searchParams.value.pageNum
        }${auditLogFilter()}`
        const res = await axios.get(url).catch((error) => {
            if (error.response) {
                // Request made and server responded
                alert(error.response.data.data.err_message)
            } else if (error.request) {
                // The request was made but no response was received
            } else {
                // Something happened in setting up the request that triggered an Error
            }
        })
        if (res.data.Success) {
            auditLog.value = res.data.data.list
            isLoading.value = false
        } else
            alert("Failed to get audit log for availability, please try again.")
    } else if (
        type === "pricing" &&
        ((!props.showCalendar && props.date) || props.showCalendar)
    ) {
        const url = `${process.env.VUE_APP_API_BASE_URL}/auditLog/price/${
            space.value.space_id
        }?space_type=${space.value.space_type}&country_code=${
            space.value.country_code
        }&page=${searchParams.value.pageNum}${auditLogFilter()}`
        const res = await axios.get(url).catch((error) => {
            if (error.response) {
                // Request made and server responded
                alert(error.response.data.data.err_message)
            } else if (error.request) {
                // The request was made but no response was received
            } else {
                // Something happened in setting up the request that triggered an Error
            }
        })
        if (res.data.Success) {
            auditLog.value = res.data.data.list
            isLoading.value = false
        } else alert("Failed to get audit log for pricing, please try again.")
    } else isLoading.value = false
}

async function toSpecificPage(val) {
    searchParams.value.pageNum = parseInt(val)
    await getAuditLog(props.type)
}

async function pageButtonAction(action) {
    if (action === "next") {
        searchParams.value.pageNum += 1
        await getAuditLog(props.type)
    }
    if (action === "prev" && searchParams.value.pageNum > 1) {
        searchParams.value.pageNum -= 1
        await getAuditLog(props.type)
    }
}

function formateBookType(str) {
    switch (str) {
        case "hour":
            return "Flexible Session"
        case "session":
            return "Session"
        case "overnight":
            return "Overnight"
        case "hourly":
            return "Hourly"
        case "day":
            return "Day Pass"
    }
}

function formateUpdateTime(str) {
    const date = new Date(str)
    if (str && !isNaN(+date)) {
        let d = date.getDate()
        let m = date.getMonth()
        let hour = date.getHours()
        let minute = date.getMinutes()
        return `${d < 10 ? "0" + d : d}/${m + 1 < 10 ? "0" + (m + 1) : m + 1} ${
            hour < 10 ? "0" + hour : hour
        }:${minute < 10 ? "0" + minute : minute}`
    } else return str
}

function formateUpdateDate(str) {
    const date = new Date(str)
    if (str && !isNaN(+date)) {
        let d = date.getDate()
        let m = date.getMonth()
        return `${d < 10 ? "0" + d : d}/${m + 1 < 10 ? "0" + (m + 1) : m + 1}`
    } else return str
}

function formateDate(str) {
    const date = new Date(str)
    if (str && !isNaN(+date)) {
        let y = date.getFullYear()
        let d = date.getDate()
        let m = date.getMonth()
        return `${y}-${m + 1 < 10 ? "0" + (m + 1) : m + 1}-${
            d < 10 ? "0" + d : d
        }`
    } else return str
}

function formateTime(str) {
    if (str) {
        return `${str.split(":")[0]}:${str.split(":")[1]}`
    } else return str
}

function formatePricePriority(val) {
    switch (val) {
        case "per_day":
            return "Specific Date"
        case "per_month":
            return "Monthly"
        case "default":
            return "Default"
    }
}

function formateVenueType(val) {
    let name = venueTypes.value.filter((e) => e.venue_type === val)
    if (name.length > 0) {
        return name[0].name
    } else return val
}

function formateDuration(val) {
    if (parseInt(val) < 60) {
        return `${val} Minute(s)`
    } else if (parseInt(val) < 1440) {
        return `${parseInt(val) / 60} Hour(s)`
    } else return `${parseInt(val) / 1440} Day(s)`
}

function formateRefundMin(val) {
    if (parseInt(val) === 0) {
        return "Anytime"
    } else if (parseInt(val) < 60) {
        return `${val}-Mins Prior`
    } else if (parseInt(val) < 1440) {
        return `${parseInt(val) / 60}-Hours Prior`
    } else return `${parseInt(val) / 1440}-Days Prior`
}

function resetFilters() {
    searchParams.value = {
        venue_type: [""],
        book_type: "",
        book_ids: "",
        distributor: "",
        dates: [],
        pageNum: 1
    }
}

watch(
    () => props.selectedSpace,
    () => {
        space.value = props.user.space_info_list[props.selectedSpace]
        resetFilters()
        if (isShow.value) {
            getVenueType()
            getTimeSlot()
            getAuditLog(props.type)
        }
    }
)

watch(
    () => props.date,
    () => {
        resetFilters()
        if (isShow.value) {
            getAuditLog(props.type)
        }
    }
)

watch(
    () => props.type,
    () => {
        resetFilters()
        if (isShow.value) {
            getAuditLog(props.type)
        }
    }
)
</script>

<style scoped>
>>> {
    --vs-controls-color: #6b7280;
    --vs-border-color: #e5e7eb;

    --vs-dropdown-bg: #282c34;
    --vs-dropdown-color: #ffffff;
    --vs-dropdown-option-color: #ffffff;

    --vs-selected-border-color: #facc15;

    --vs-selected-bg: #facc15;
    --vs-selected-color: #000000;

    --vs-search-input-color: #6b7280;

    --vs-dropdown-option--active-bg: #facc15;
    --vs-dropdown-option--active-color: #ffffff;
}
</style>
